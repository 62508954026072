<template>
	<!-- container -->
	<div>
		<!-- titArea -->
		<div class="titArea mNone">
			<div class="inner">
				<h2>건물 수정</h2>
			</div>
		</div>
		<!--// titArea -->
		<BuildingFrom :value="data" @save="onSave" labelCancel="건물삭제" labelSave="저장"></BuildingFrom>
	</div>
	<!--// container -->
</template>
<script>
import { hideMenu, showSpinner, hideSpinner, getCoordinate } from '@/utils/cmm';
import building from '@/api/building';
import file from '@/api/file';
import buildingFrom from './buildingForm';

export default {
	components: {
		BuildingFrom: buildingFrom,
	},
	data() {
		return {
			data: {},
		};
	},
	computed: {
		buildingId() {
			return this.$route.params.id;
		},
	},
	mounted() {
		hideMenu();
		this.reload();
	},
	methods: {
		async onSave() {
			const data = this.data;
			try {
				showSpinner();
				let req = {
					id: data.id,
					name: data.name,
					basementFloors: data.basementFloors,
					groundFloors: data.groundFloors,
					zip: data.zip,
					address: data.address,
					addressDetail: data.addressDetail,
					imageId: data.imageId,
					rooftopYn: data.rooftopYn,
				};

				if (data.imageFile) {
					let res = await file.upload({ file: data.imageFile });
					req.imageId = res.data[0].id;
				}

				let res = await getCoordinate(this.data.address);
				if (res) {
					req.longitude = res.longitude;
					req.latitude = res.latitude;
				}
				await building.update(req);
				this.$router.push({ name: 'buildingDetail', params: { id: this.buildingId } });
			} catch (ex) {
				await this.$alert(`건물 조회 에러가 발생했습니다.\r\n(${ex.message})`);
				return this.$router.go(-1);
			} finally {
				hideSpinner();
			}
		},
		async reload() {
			try {
				showSpinner();
				let res = await building.getOne(this.buildingId);
				this.data = res;
				if (this.data.zip == null) {
					this.data.zip = '';
				}
				if (this.data.address == null) {
					this.data.address = '';
				}
				if (this.data.addressDetail == null) {
					this.data.addressDetail = '';
				}
				this.data.orgAddress = this.data.address;
			} catch (ex) {
				await this.$alert(`건물 조회 에러가 발생했습니다.\r\n(${ex.message})`);
				return this.$router.go(-1);
			} finally {
				hideSpinner();
			}
		},
	},
};
</script>

<style>
.buildWrap .buildArea .build,
.buildWrap .buildArea .build ul {
	background: none;
}
</style>
