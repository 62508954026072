<template>
	<!-- container -->
	<div>
		<!-- fullArea -->
		<div class="fullArea">
			<!-- buildWrap -->
			<div class="buildWrap">
				<!-- buildArea -->
				<div class="buildArea">
					<!-- build -->
					<div class="build">
						<transition-group name="floor" tag="ul" class="floor-reverse">
							<li class="floor-item" v-for="(item, idx) in grounds" :key="idx">{{ item }}</li>
						</transition-group>
						<transition-group name="floor" tag="ul">
							<li class="floor-item" v-for="(item, idx) in basements" :key="idx">{{ item }}</li>
						</transition-group>
					</div>
					<!--// build -->

					<!-- legend -->
					<div class="legend">
						<span class="v1" @click="show = !show">지상</span>
						<span class="v2" @click="show = !show">지하</span>
					</div>
					<!--// legend -->
				</div>
				<!--// buildArea -->
				<!-- inputWrap -->
				<div class="inputWrap">
					<!-- inputTable -->
					<div class="inputTable">
						<table>
							<caption>
								건물명, 건물지상층, 건물지하층, 건물 주소 입력, 건물 사진 첨부 항목으로 구성된 표
							</caption>
							<colgroup>
								<col style="width:110px" />
								<col style="width:auto" />
							</colgroup>
							<tbody>
								<tr>
									<th scope="row">건물명 <span>*</span></th>
									<td>
										<p>
											<input
												type="text"
												title="건물명 입력"
												placeholder="건물명을 입력해주세요."
												required="required"
												v-model.trim="value.name"
												maxlength="254"
											/>
											<a href="javascript:void(0)" class="btnDel" @click="value.name = ''">텍스트 삭제</a>
										</p>
									</td>
								</tr>
								<tr>
									<th scope="row">건물지상층 <span>*</span></th>
									<td>
										<select title="건물지상층 선택" v-model="value.groundFloors" @change="addRooftop(value.rooftopYn)">
											<option value="1">1층</option>
											<option value="2">2층</option>
											<option value="3">3층</option>
											<option value="4">4층</option>
											<option value="5">5층</option>
											<option value="6">6층</option>
											<option value="7">7층</option>
											<option value="8">8층</option>
											<option value="9">9층</option>
											<option value="10">10층</option>
											<option value="11">11층</option>
											<option value="12">12층</option>
											<option value="13">13층</option>
											<option value="14">14층</option>
											<option value="15">15층</option>
											<option value="16">16층</option>
											<option value="17">17층</option>
											<option value="18">18층</option>
											<option value="19">19층</option>
											<option value="20">20층</option>
										</select>
									</td>
								</tr>
								<tr>
									<th scope="row">옥상포함</th>
									<td>
										<span class="checkbox">
											<input
												type="checkbox"
												id="rooftop"
												v-model="value.rooftopYn"
												value="Y"
												true-value="Y"
												false-value="N"
												@click="addRooftop(value.rooftopYn == 'Y' ? 'N' : 'Y')"
											/>
											<label for="rooftop"></label>
										</span>
									</td>
								</tr>
								<tr>
									<th scope="row">건물지하층 <span>*</span></th>
									<td>
										<select title="건물지하층 선택" v-model="value.basementFloors">
											<option value="0">0층</option>
											<option value="1">1층</option>
											<option value="2">2층</option>
											<option value="3">3층</option>
											<option value="4">4층</option>
											<option value="5">5층</option>
										</select>
									</td>
								</tr>
								<tr>
									<th scope="row">우편번호</th>
									<td>
										<p class="flex">
											<input type="text" title="우편번호" v-model.trim="value.zip" readonly />
											<button type="button" class="btn" @click="openDaumPostcode">주소선택</button>
										</p>
									</td>
								</tr>

								<tr>
									<th scope="row">주소</th>
									<td>
										<input type="text" title="주소 입력" v-model.trim="value.address" readonly />
									</td>
								</tr>
								<tr>
									<th scope="row">상세 주소</th>
									<td>
										<p>
											<input
												type="text"
												title="상세주소 입력"
												placeholder="주소를 입력해주세요."
												required="required"
												v-model="value.addressDetail"
												maxlength="254"
												:disabled="!value.zip && !value.address"
											/>
											<a href="javascript:void(0)" class="btnDel" @click="delAddress()">텍스트 삭제</a>
										</p>
										<p
											v-if="value.latitude && value.longitude && value.address === value.orgAddress"
											style="font-size:small; color:#999;"
										>
											<span>위도: {{ value.latitude }}, 경도: {{ value.longitude }}</span>
										</p>
									</td>
								</tr>
								<tr>
									<th scope="row"></th>
									<td>
										<div ref="wrap" class="daumAddress" style=" position: relative ">
											<img
												src="//t1.daumcdn.net/postcode/resource/images/close.png"
												id="btnFoldWrap"
												style="cursor:pointer;position:absolute;right:0px;z-index:1"
												@click="foldDaumPostcode"
												alt="접기 버튼"
											/>
										</div>
									</td>
								</tr>
								<tr>
									<th scope="row" class="mNone">건물 사진 첨부</th>
									<td>
										<div class="file">
											<input type="file" title="건물 사진 첨부" required="required" @change="onChangeImage" />
											<span>건물 사진 첨부하기</span>
										</div>
										<!-- imgFileList -->
										<ul class="imgFileList">
											<li v-if="imageUrl">
												<div class="imgArea"><img :src="imageUrl" alt="" /></div>
												<a href="javascript:void(0)" class="btnDel" @click="onRemoveImage">삭제</a>
											</li>
										</ul>
										<!--// imgFileList -->
									</td>
								</tr>
							</tbody>
						</table>
					</div>

					<!--// inputTable -->
					<!-- btnBottom -->
					<div class="btnBottom">
						<button type="button" class="btn btnGray" @click="onCancel">{{ labelCancel }}</button>
						<button type="button" class="btn" :disabled="!value.name" @click="onSave">
							{{ labelSave }}
						</button>
					</div>
					<!--// btnBottom -->
				</div>
				<!--// inputWrap -->
			</div>
			<!--// buildWrap -->
		</div>
		<!--// fullArea -->
	</div>
	<!--// container -->
</template>
<script>
import building from '@/api/building';
import { showSpinner, hideSpinner } from '@/utils/cmm';
export default {
	props: {
		isEdit: {
			type: Boolean,
			default: true,
		},
		value: {
			type: Object,
			default: function() {
				return {};
			},
		},
		labelSave: {
			type: String,
			default: '건물등록',
		},
		labelCancel: {
			type: String,
			default: '취소',
		},
	},
	data() {
		return {
			imageUrl: null,
			show: true,
			orgAddress: null,
		};
	},
	computed: {
		grounds() {
			const groundFloors = Number(this.value.groundFloors || 0);
			let ret = new Array(groundFloors);
			for (let i = 0; i < groundFloors; ++i) {
				//ret[i] = `${groundFloors - i}층`;
				ret[i] = `${i + 1}층`;
			}
			return ret;
		},
		basements() {
			const basementFloors = Number(this.value.basementFloors || 0);
			let ret = new Array(basementFloors);
			for (let i = 0; i < basementFloors; ++i) {
				ret[i] = `${i + 1}층`;
			}
			return ret;
		},
	},
	watch: {
		value(newVal) {
			this.imageUrl = newVal && newVal.imageId ? `/file/${newVal.imageId}` : null;
			if (newVal.rooftopYn == 'Y') {
				this.setRooftop();
			}
		},
	},
	methods: {
		onChangeImage({ target }) {
			let file = target.files[0];
			if (!file) return;

			if (file.size > 1024 * 1024 * 10) {
				return this.$alert('이미지 크기는 10MB를 초과할 수 없습니다.');
			}

			this.imageUrl = URL.createObjectURL(file);
			this.value.imageFile = file;
		},
		onRemoveImage() {
			if (!this.imageUrl) return;
			let imageUrl = this.imageUrl;
			this.imageUrl = null;
			if (imageUrl.startsWith('blob:')) {
				URL.revokeObjectURL(imageUrl);
			} else {
				this.value.imageId = null;
			}
		},
		async onCancel() {
			// 빌등삭제
			if (this.value.id) {
				if (
					!(await this.$confirm(
						'빌딩을 삭제하시겠습니까?<br><font color="red">관련 자료는 모두 삭제됩니다.</font>',
						'빌딩삭제 안내',
					))
				)
					return false;
				try {
					showSpinner();
					await building.remove(this.value.id);
					this.$router.push({ name: 'buildingList' });
				} catch (ex) {
					return await this.$alert(`${ex.message}`);
				} finally {
					hideSpinner();
				}
			} else {
				if (await this.$confirm('취소하시겠습니다?', '건물 안내')) {
					this.$router.push({ name: 'buildingList' });
				}
			}
		},
		onSave() {
			this.$emit('save');
			this.onRemoveImage();
		},
		openDaumPostcode() {
			const elementWrap = this.$refs.wrap;
			// 현재 scroll 위치를 저장해놓는다.
			const currentScroll = Math.max(document.body.scrollTop, document.documentElement.scrollTop);
			new window.daum.Postcode({
				oncomplete: data => {
					// 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.
					// 도로명 주소의 노출 규칙에 따라 주소를 조합한다.
					// 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
					let fullRoadAddr = data.roadAddress;
					// 도로명 주소 변수
					let extraRoadAddr = '';
					// 도로명 조합형 주소 변수
					// 법정동명이 있을 경우 추가한다. (법정리는 제외)
					// 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
					if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
						extraRoadAddr += data.bname;
					}
					// 건물명이 있고, 공동주택일 경우 추가한다.
					if (data.buildingName !== '' && data.apartment === 'Y') {
						extraRoadAddr += extraRoadAddr !== '' ? ', ' + data.buildingName : data.buildingName;
					}
					// 도로명, 지번 조합형 주소가 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
					if (extraRoadAddr !== '') {
						extraRoadAddr = ' (' + extraRoadAddr + ')';
					}
					// 도로명, 지번 주소의 유무에 따라 해당 조합형 주소를 추가한다.
					if (fullRoadAddr !== '') {
						fullRoadAddr += extraRoadAddr;
					}
					// 우편번호와 주소 정보를 해당 필드에 넣는다.
					this.value.zip = data.zonecode;

					//5자리 새우편번호 사용
					this.value.address = fullRoadAddr;
					this.$forceUpdate();

					// iframe을 넣은 element를 안보이게 한다.
					// (autoClose:false 기능을 이용한다면, 아래 코드를 제거해야 화면에서 사라지지 않는다.)
					elementWrap.style.display = 'none';

					// 우편번호 찾기 화면이 보이기 이전으로 scroll 위치를 되돌린다.
					document.body.scrollTop = currentScroll;
				},
				// 우편번호 찾기 화면 크기가 조정되었을때 실행할 코드를 작성하는 부분. iframe을 넣은 element의 높이값을 조정한다.
				onresize: function(size) {
					elementWrap.style.height = size.height + 'px';
				},
				width: '100%',
				height: '100%',
			}).embed(elementWrap);
			elementWrap.style.display = 'block';
		},
		foldDaumPostcode() {
			const elementWrap = this.$refs.wrap;
			elementWrap.style.display = 'none';
		},

		deleteAddress() {
			this.value.btnBool = true;
			this.value.zip = null;
			this.value.address = null;
		},
		addRooftop(v) {
			if (v == 'Y') {
				this.grounds.push('옥상');
			} else if (v == 'N' && this.grounds[this.grounds.length - 1] == '옥상') {
				this.grounds.pop();
			}
		},
		setRooftop() {
			this.grounds.push('옥상');
		},
		delAddress() {
			this.value.addressDetail = null;
			this.$forceUpdate();
		},
	},
};
</script>

<style scoped>
.build ul {
	padding-right: 10px !important;
}

.build li:first-child {
	margin-top: 6px;
}

.floor-item {
	transition: all 0.5s ease;
}

.floor-enter,
.floor-leave-to {
	transform: translateX(10px);
	opacity: 0;
}

.floor-reverse {
	display: flex;
	flex-direction: column-reverse;
}
.btnBottom {
	margin-top: 10px;
}
</style>
